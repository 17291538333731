import { useAppDispatch } from "@/store";
import { authActions } from "@/store/slices/auth";
import { useFormik } from "formik";
import Image from "next/image";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styles from "@/styles/forgotpassword.module.scss";
import * as Yup from "yup";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import { toast } from "react-toastify";
import { phoneRegExp } from "@/utils/regex";
import { fbqTrackEvent } from "@/lib/fpixel";
import Link from "next/link";
import moment from "moment-timezone";
import { unbxdCusEngTrackEvent, unbxdCusEngTrackUser } from "@/lib/unbxd";

const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otp_uid, setUid] = useState(false);
  const currentDate = moment().format("DD-MM-YYYY");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      pincode: "",
      consent: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      consent: Yup.boolean()
        .oneOf([true], "You must accept the personal data processing consent")
        .required("You must accept the personal data processing consent"),

      mobile: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .test(
          "len",
          "Mobile number needs to be exactly 10 digits",
          (val: any) => val?.toString().length === 10
        )
        .required("Mobile is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{6}$/, "Invalid pincode. Must be a 6-digit number"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (window.Moengage) {
        window.Moengage.track_event("Registration Initiated", {
          mobile: values.mobile,
          name: values.name,
          email: values.email,
          pincode: values.pincode,
          platform: "website",
        });
      }

      try {
        const { data } = await httpClient.post(endpoints.auth.sendOtp(), {
          mobile: values.mobile,
          email: values.email,
        });
        toast(data.message, {
          type: "success",
          toastId: "auth-otp-success",
        });
        if (window.Moengage) {
          window.Moengage.track_event("Registration Submitted", {
            mobile: values.mobile,
            name: values.name,
            email: values.email,
            pincode: values.pincode,
            platform: "website",
          });
        }
        setStep(2);
        setUid(data.otp_uuid);
      } catch (error: any) {
        if (error.response.data?.message) {
          toast(error.response.data?.message as string, {
            type: "error",
            toastId: "auth-otp-error",
          });
        } else {
          toast("Something went wrong! Try again!", {
            type: "error",
            toastId: "auth-otp-error",
          });
        }
      }
      setLoading(false);
    },
  });

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("OTP is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (window.Moengage) {
        window.Moengage.track_event("Verification Initiated", {
          mobile: formik.values.mobile,
          platform: "website",
        });
      }

      try {
        const { data } = await httpClient.post(endpoints.auth.signup(), {
          name: formik.values.name,
          mobile: formik.values.mobile,
          email: formik.values.email,
          pincode: formik.values.pincode,
          otp: values.otp,
          consent: formik.values.consent,
          otp_uuid: otp_uid,
          source: "signup_popup",
        });
        toast(data.message, {
          type: "success",
          toastId: "auth-otp-success",
        });

        if (window.Moengage) {
          window.Moengage.track_event("Verification Submitted", {
            mobile: formik.values.mobile,
            platform: "website",
          });

          window.Moengage.track_event("Registration Completed", {
            mobile: formik.values.mobile,
            platform: "website",
          });
        }

        if (window.smartech) {
          unbxdCusEngTrackUser({
            "pk^mobile": formik.values.mobile,
            NAME: formik.values.name,
            email: formik.values.email,
            PINCODE: formik.values.pincode,
            SENCO_USER: "yes",
            REGISTRATION_DATE_SENCO: currentDate,
          });

          window.smartech("identify", formik.values.mobile);
          setTimeout(() => {
            unbxdCusEngTrackEvent("Registration", {
              source: "web",
              platform: "senco",
            });
          }, 200);
        }

        fbqTrackEvent("CompleteRegistration", { status: "registered" });
        toast("Registration Successful! Please login to continue", {
          type: "success",
          toastId: "auth-registration-success",
        });
        dispatch(authActions.setActiveTab("login"));
      } catch (error: any) {
        if (error.response.data?.message) {
          toast(error.response.data?.message as string, {
            type: "error",
            toastId: "auth-otp-error",
          });
        } else {
          toast("Something went wrong! Try again!", {
            type: "error",
            toastId: "auth-otp-error",
          });
        }
      }
      setLoading(false);
    },
  });

  return (
    <div className={styles.sign_up_container}>
      <div className={styles.login_image}>
        <Image
          src="https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/EXfVWvIz5SFqWvT8MjQ2kNZT0z7fLpusbEtpVMCo.jpeg"
          alt="Login image"
          width={399}
          height={532}
        />
      </div>
      {step == 1 && (
        <div className={styles.signup_form_wrapper}>
          <form onSubmit={formik.handleSubmit}>
            <h1>Create new account</h1>
            <div className="form-item">
              <input
                type="text"
                placeholder="Name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <small className="text-danger">{formik.errors.name}</small>
              ) : null}
            </div>
            <div className="form-item">
              <input
                type="email"
                placeholder="Email"
                autoComplete="new-email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <small className="text-danger">{formik.errors.email}</small>
              ) : null}
            </div>
            <div className="form-item">
              <input
                type="text"
                placeholder="Mobile No."
                maxLength={10}
                {...formik.getFieldProps("mobile")}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <small className="text-danger">{formik.errors.mobile}</small>
              ) : null}
            </div>
            <div className="form-item">
              <input
                type="text"
                placeholder="Pincode"
                {...formik.getFieldProps("pincode")}
              />
              {formik.touched.pincode && formik.errors.pincode ? (
                <small className="text-danger">{formik.errors.pincode}</small>
              ) : null}
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                style={{ width: "16px", padding: "0px" }}
                type="checkbox"
                id="html"
                checked={formik.values.consent}
                onChange={(e) =>
                  formik.setFieldValue("consent", e.target.checked)
                }
              />
              <label htmlFor="html" className="form-check-label">
                {" "}
                I Accept{" "}
                <Link href={"/data-privacy-consent"}>
                  <a target="_blank" style={{ color: "#93101d" }}>
                    Personal Data Processing Consent{" "}
                  </a>
                </Link>
              </label>
              {formik.touched.consent && formik.errors.consent ? (
                <small className="text-danger">{formik.errors.consent}</small>
              ) : null}
            </div>

            <button
              className={styles.submit_btn}
              type="submit"
              disabled={loading}
            >
              Register {loading && <Spinner animation="border" size="sm" />}
            </button>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Already have an account?
                  <span
                    onClick={() => dispatch(authActions.setActiveTab("login"))}
                    className="ms-1 pointer"
                  >
                    Click to login.
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
      {step == 2 && (
        <div className={styles.signup_form_wrapper}>
          <form onSubmit={otpFormik.handleSubmit}>
            <h1>Verify OTP</h1>
            <div className="form-item">
              <input
                type="text"
                placeholder="OTP"
                {...otpFormik.getFieldProps("otp")}
              />
              {otpFormik.touched.otp && otpFormik.errors.otp ? (
                <small className="text-danger">{otpFormik.errors.otp}</small>
              ) : null}
            </div>
            <button
              role="button"
              aria-label="Verify"
              className={styles.submit_btn}
              type="submit"
              disabled={loading}
            >
              Verify {loading && <Spinner animation="border" size="sm" />}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
